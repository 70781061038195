import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getClientSalesmenMetrics,
  getRankByEmail,
  getRankByStableAccount,
  getRankByWgsDetails,
} from "../../../store/thunks/portalManagementThunk";
import {
  GetArray,
  GetObject,
  WithCommas,
  exportToExcel,
} from "../../../utils/utils";
import { BarChart } from "@mui/x-charts";
import MiniSpinner from "../../reusableComponents/miniSpinner/miniSpinner";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Bar,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import TableTemplate from "../../reusableComponents/tableTemplate/tableTemplate";
import DashboardHeader from "../../reusableComponents/dashboardHeader/dashboardHeader";
import { logoutUser } from "../../../store/thunks/authenticationThunk";
import { portalManagementReset } from "../../../store/features/portalManagement/portalManagementSlice";
import { systemManagementReset } from "../../../store/features/accountManagement/systemManagementSlice";
import { logout } from "../../../store/features/authentication/autehnticationSlice";
import { useNavigate } from "react-router-dom";

const SaleRepView = () => {
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const {
    selectedClient,
    selectedSaleRep,
    salemRawData,
    salstbRawData,
    rankByStableAccountLoading,
    rankByEmailLoading,
    rankByWgsDetailsLoading,
    rankByWgsDetailsRawData,
    selectedLastUpdated,
    clientSalesmenData,
    clientSalesmenMetricsLoading,
  } = useAppSelector((state: any) => state.portalManagement);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [salemChartData, setSalemChartData] = useState([]);
  const [salemChartLabels, setSalemChartLabels] = useState([]);

  const [salstbChartData, setSalstbChartData] = useState([]);
  const [salstbChartLabels, setSalstbChartLabels] = useState([]);

  const [originalBranch, setOriginalBranch] = useState<any>("Overall");

  const [salesRepBranches, setSalesRepBranches] = useState<any>([]);
  const [salesRepList, setSalesRepList] = useState<any>([]);

  const [selectedSaleRepSaleRep, setSelectedSalesRepSaleRep] =
    useState<any>(null);
  const [selectedSaleRepBranch, setSelectedSaleRepBranch] = useState<any>(null);

  const [selectedSaleRepRetLead, setSelectedSaleRepRetLead] =
    useState<any>(null);

  const [gainLostTotalData, setGainLostTotalData] = useState<any>([]);

  const [retentionLeadTableData, setRetentionLeadTableData] = useState<any>([]);

  const [clientSalesmenChartYou, setClientSalesmenChartYou] = useState<any>([]);
  const [
    clientSalesmenChartMetricAverage,
    setClientSalesmenChartMetricAverage,
  ] = useState<any>([]);
  const [clientSalesmenChartLabel, setClientSalesmenChartLabel] = useState<any>(
    []
  );

  const [sgain, setSgain] = useState(0);
  const [slost, setSlost] = useState(0);
  const [sacc, setSacc] = useState(0);

  const retentionLeadOption: any[] = [
    { label: "Lead 9-12 Weeks", value: "Lead" },
    { label: "Lost Customers", value: "Lost" },
    { label: "Gained Customers", value: "Gain" },
    { label: "All Customers", value: "Last Purchase" },
  ];

  const [retLeadError, setRetLeadError] = useState(false);
  const [retLeadErrorMessage, setRetLeadErrorMessage] = useState("");

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        getRankByStableAccount({
          customer_id: selectedClient.customer_id,
        })
      ).then((result: any) => {
        if (result?.error) {
          if (result?.error?.message === "Rejected") {
            dispatch(logoutUser({ user_index: userInfo?.client_user_index }));
            dispatch(portalManagementReset());
            dispatch(systemManagementReset());
            dispatch(logout());
            navigate("/login");
          }
        }
      });
      dispatch(
        getRankByEmail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getRankByWgsDetails({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getClientSalesmenMetrics({
          customer_id: selectedClient.customer_id,
          salesrep: selectedSaleRep?.SALESMAN,
        })
      );
    }
  }, []);

  const salesRepFilterData = () => {
    if (salemRawData) {
      const salemFilter = GetObject(
        GetArray(salemRawData).filter((item: any) => {
          return (
            item.BRANCH ===
            (selectedSaleRepBranch
              ? selectedSaleRepBranch?.value
              : originalBranch)
          );
        })
      );

      if (Object.keys(salemFilter).length !== 0) {
        setSalemChartData(salemFilter.EMAIL_PERCENT);
        setSalemChartLabels(salemFilter.SALESMAN);
      } else {
        setSalemChartData([]);
        setSalemChartLabels([]);
      }
    }

    if (salstbRawData) {
      const salstbFilter = GetObject(
        GetArray(salstbRawData).filter((item: any) => {
          return (
            item.BRANCH ===
            (selectedSaleRepBranch
              ? selectedSaleRepBranch?.value
              : originalBranch)
          );
        })
      );
      if (Object.keys(salstbFilter).length !== 0) {
        setSalstbChartData(salstbFilter.STABLE_PERCENT);
        setSalstbChartLabels(salstbFilter.SALESMAN);
      } else {
        setSalstbChartData([]);
        setSalstbChartLabels([]);
      }
    }
  };

  const salesRepMetricsFilterData = () => {
    if (clientSalesmenData) {
      setClientSalesmenChartYou(
        clientSalesmenData?.salesman_rank_metric?.SALESMAN_LABEL
      );
      setClientSalesmenChartMetricAverage(
        clientSalesmenData?.salesman_rank_metric?.AVG_METRIC
      );
      setClientSalesmenChartLabel(
        clientSalesmenData?.salesman_rank_metric?.KEY_METRIC
      );
    } else {
      setClientSalesmenChartYou([]);
      setClientSalesmenChartMetricAverage([]);
      setClientSalesmenChartLabel([]);
    }
  };

  const handleBranchChange = (selection: any) => {
    let tempBranchSelection: string = "";
    setSelectedSaleRepBranch(selection);

    if (selection) {
      tempBranchSelection = selection.value;
    } else {
      tempBranchSelection = originalBranch;
    }

    const tempSalesRepList: any[] = [];

    rankByWgsDetailsRawData?.unique_salesman?.BRANCH.forEach(
      (element: any, index: any) => {
        if (element === tempBranchSelection) {
          tempSalesRepList.push({
            label: rankByWgsDetailsRawData?.unique_salesman?.Salesman[index],
            value: rankByWgsDetailsRawData?.unique_salesman?.Salesman[index],
          });
        }
      }
    );

    setSalesRepList(tempSalesRepList);
    setSelectedSalesRepSaleRep(
      tempSalesRepList.length > 0 ? tempSalesRepList[0] : null
    );

    let tempSalesRep = "";

    if (tempSalesRepList.length > 0) {
      tempSalesRep = tempSalesRepList[0]?.value;
    } else {
      tempSalesRep = selectedSaleRep?.SALESMAN;
    }

    if (rankByWgsDetailsRawData?.salesman_12_metric) {
      const sal12Filter = GetObject(
        GetArray(rankByWgsDetailsRawData?.salesman_12_metric).filter(
          (item: any) =>
            item.BRANCH === tempBranchSelection &&
            item.SALESMAN === tempSalesRep
        )
      );

      const tempGainLostTotalData: any[] = [];
      if (sal12Filter.END_PERIOD) {
        sal12Filter?.END_PERIOD.forEach((element: any, index: any) => {
          tempGainLostTotalData.push({
            name: element,
            Gained: sal12Filter?.GAINED_ACCOUNT[index],
            Lost: sal12Filter?.LOST_ACCOUNT[index],
            Active: sal12Filter?.TOTAL_ACCOUNT[index],
          });
        });
      }

      setGainLostTotalData(tempGainLostTotalData);

      setSgain(
        sal12Filter.GAINED_ACCOUNT
          ? sal12Filter.GAINED_ACCOUNT[sal12Filter.GAINED_ACCOUNT.length - 1]
          : 0
      );
      setSlost(
        sal12Filter.LOST_ACCOUNT
          ? sal12Filter.LOST_ACCOUNT[sal12Filter.LOST_ACCOUNT.length - 1]
          : 0
      );
      setSacc(
        sal12Filter.TOTAL_ACCOUNT
          ? sal12Filter.TOTAL_ACCOUNT[sal12Filter.TOTAL_ACCOUNT.length - 1]
          : 0
      );
    }

    setSelectedSaleRepRetLead(null);
    setRetentionLeadTableData([]);
  };
  const handleSaleRepChange = (selection: any) => {
    let tempSaleRepSelection: string = "";
    if (selection) {
      setSelectedSalesRepSaleRep(selection);
      tempSaleRepSelection = selection.value;
    } else {
      setSelectedSalesRepSaleRep(salesRepList[0]);
      tempSaleRepSelection = salesRepList[0].value;
    }

    if (rankByWgsDetailsRawData.salesman_12_metric) {
      const sal12Filter = GetObject(
        GetArray(rankByWgsDetailsRawData.salesman_12_metric).filter(
          (item: any) =>
            item.BRANCH === selectedSaleRepBranch.value &&
            item.SALESMAN === tempSaleRepSelection
        )
      );

      const tempGainLostTotalData: any[] = [];
      if (sal12Filter.END_PERIOD) {
        sal12Filter?.END_PERIOD.forEach((element: any, index: any) => {
          tempGainLostTotalData.push({
            name: element,
            Gained: sal12Filter?.GAINED_ACCOUNT[index],
            Lost: sal12Filter?.LOST_ACCOUNT[index],
            Active: sal12Filter?.TOTAL_ACCOUNT[index],
          });
        });
      }

      setGainLostTotalData(tempGainLostTotalData);

      setSgain(
        sal12Filter.GAINED_ACCOUNT
          ? sal12Filter.GAINED_ACCOUNT[sal12Filter.GAINED_ACCOUNT.length - 1]
          : 0
      );
      setSlost(
        sal12Filter.LOST_ACCOUNT
          ? sal12Filter.LOST_ACCOUNT[sal12Filter.LOST_ACCOUNT.length - 1]
          : 0
      );
      setSacc(
        sal12Filter.TOTAL_ACCOUNT
          ? sal12Filter.TOTAL_ACCOUNT[sal12Filter.TOTAL_ACCOUNT.length - 1]
          : 0
      );
    }

    setSelectedSaleRepRetLead(null);
    setRetentionLeadTableData([]);
  };
  const handleRetentionLeadChange = (selection: any) => {
    setRetLeadError(false);
    setRetLeadErrorMessage("");
    if (!selectedSaleRepBranch || !selectedSaleRepSaleRep) {
      setRetLeadError(true);
      setRetLeadErrorMessage("Please select a branch and sales rep first");
      return;
    }

    setSelectedSaleRepRetLead(selection);

    if (selection) {
      if (rankByWgsDetailsRawData?.salesman_lead) {
        setRetentionLeadTableData(
          rankByWgsDetailsRawData?.salesman_lead[selection?.value][
            selectedSaleRepBranch?.value
          ][selectedSaleRepSaleRep?.value]
        );
      }
    }
  };

  const salesRepFilterDetailData = () => {
    if (rankByWgsDetailsRawData) {
      const tempSalesRepBranches: any[] = [];
      const tempSalesRepList: any[] = [];

      rankByWgsDetailsRawData.sal_branch.forEach((element: any) => {
        tempSalesRepBranches.push({
          label: element,
          value: element,
        });
      });

      setSalesRepBranches(tempSalesRepBranches);

      let tempSelectedBranch = "";
      let tempSelectedSalesRep = selectedSaleRep.SALESMAN;

      rankByWgsDetailsRawData.unique_salesman.Salesman.forEach(
        (element: any, index: any) => {
          if (element === selectedSaleRep?.SALESMAN) {
            tempSelectedBranch =
              rankByWgsDetailsRawData.unique_salesman.BRANCH[index];
            setOriginalBranch(tempSelectedBranch);
            setSelectedSaleRepBranch({
              label: tempSelectedBranch,
              value: tempSelectedBranch,
            });

            setSelectedSalesRepSaleRep({
              label: element,
              value: element,
            });
          }
        }
      );

      if (tempSelectedBranch === "") {
        tempSelectedBranch = originalBranch;
      }
      rankByWgsDetailsRawData.unique_salesman.BRANCH.forEach(
        (element: any, index: any) => {
          if (element === tempSelectedBranch) {
            tempSalesRepList.push({
              label: rankByWgsDetailsRawData.unique_salesman.Salesman[index],
              value: rankByWgsDetailsRawData.unique_salesman.Salesman[index],
            });
          }
        }
      );

      setSalesRepList(tempSalesRepList);

      if (rankByWgsDetailsRawData.salesman_12_metric) {
        const sal12Filter = GetObject(
          GetArray(rankByWgsDetailsRawData?.salesman_12_metric).filter(
            (item: any) =>
              item.BRANCH ===
                (selectedSaleRepBranch
                  ? selectedSaleRepBranch?.value
                  : originalBranch) && item.SALESMAN === tempSelectedSalesRep
          )
        );

        const tempGainLostTotalData: any[] = [];
        if (sal12Filter.END_PERIOD) {
          sal12Filter?.END_PERIOD.forEach((element: any, index: any) => {
            tempGainLostTotalData.push({
              name: element,
              Gained: sal12Filter?.GAINED_ACCOUNT[index],
              Lost: sal12Filter?.LOST_ACCOUNT[index],
              Active: sal12Filter?.TOTAL_ACCOUNT[index],
            });
          });
        }

        setGainLostTotalData(tempGainLostTotalData);

        setSgain(
          sal12Filter.GAINED_ACCOUNT
            ? sal12Filter.GAINED_ACCOUNT[sal12Filter.GAINED_ACCOUNT.length - 1]
            : 0
        );
        setSlost(
          sal12Filter.LOST_ACCOUNT
            ? sal12Filter.LOST_ACCOUNT[sal12Filter.LOST_ACCOUNT.length - 1]
            : 0
        );
        setSacc(
          sal12Filter.TOTAL_ACCOUNT
            ? sal12Filter.TOTAL_ACCOUNT[sal12Filter.TOTAL_ACCOUNT.length - 1]
            : 0
        );
      }

      if (rankByWgsDetailsRawData.salesman_lead) {
        if (
          rankByWgsDetailsRawData.salesman_lead["Lead"][
            tempSelectedBranch
          ]?.hasOwnProperty(tempSelectedSalesRep)
        ) {
          setRetentionLeadTableData(
            rankByWgsDetailsRawData.salesman_lead["Lead"][tempSelectedBranch][
              tempSelectedSalesRep
            ]
          );
        } else {
          setRetentionLeadTableData([]);
        }
      }
    }
  };

  useEffect(() => {
    if (salemRawData || salstbRawData) salesRepFilterData();
  }, [salemRawData, salstbRawData, rankByWgsDetailsRawData]);

  useEffect(() => {
    if (rankByWgsDetailsRawData) salesRepFilterDetailData();
  }, [rankByWgsDetailsRawData]);

  useEffect(() => {
    if (clientSalesmenData) salesRepMetricsFilterData();
  }, [clientSalesmenData]);

  const userColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "Branch",
            header: "Branch",
          },
          {
            accessorKey: "Department",
            header: "Department",
          },
          {
            accessorKey: "Salesman",
            header: "Salesman",
          },
          {
            accessorKey: "Customer",
            header: "Customer",
          },
          {
            accessorKey: "Cust #",
            header: "Cust #",
          },
          {
            accessorKey: "Rev Rolling 12 months",
            header: "Rev Rolling 12 Months",
          },
          {
            accessorKey: "Rev Prior Rolling 12 months",
            header: "Rev Prior Rolling 12 months",
          },
          {
            accessorKey: "Order Frequency 12 months",
            header: "Order Frequency 12 months",
          },
          {
            accessorKey: "Order Frequency Prior 12 months",
            header: "Order Frequency Prior 12 months",
          },
          {
            accessorKey: "Risk Status",
            header: "Risk Status",
          },
          {
            accessorKey: "Last Purchase Date",
            header: "Last Purchase Date",
          },
          {
            accessorKey: "Contact",
            header: "Contact",
          },
          {
            accessorKey: "Phone",
            header: "Phone",
          },
          {
            accessorKey: "Email",
            header: "Email",
          },
        ],
      },
    ],
    []
  );

  const userTable = useMaterialReactTable({
    columns: userColumns,
    data: retentionLeadTableData ? retentionLeadTableData : [],
    state: {
      isLoading: rankByWgsDetailsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: false,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            exportToExcel(
              table.getPrePaginationRowModel().rows,
              "Sales Rep Ranking"
            )
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() =>
            exportToExcel(table.getRowModel().rows, "Sales Rep Ranking")
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
      </Box>
    ),
  });

  return (
    <Grid container sx={{}}>
      <DashboardHeader
        title={selectedClient.customer_name}
        headerType={""}
        lastUpdated={selectedLastUpdated}
      />
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid container item xs={4} sx={{ padding: "0px 0px 0px 10px" }}>
          <FormControl fullWidth>
            <Autocomplete
              id="Branch"
              value={selectedSaleRepBranch}
              onChange={(_: SyntheticEvent, newValue: string | null) =>
                handleBranchChange(newValue)
              }
              options={salesRepBranches}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Branches"
                  placeholder="Branches"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4} sx={{ padding: "0px 0px 0px 10px" }}>
          <FormControl fullWidth>
            <Autocomplete
              id="Sale Rep"
              value={selectedSaleRepSaleRep}
              onChange={(_: SyntheticEvent, newValue: string | null) => {
                handleSaleRepChange(newValue);
              }}
              options={salesRepList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sale Rep"
                  placeholder="Sale Rep"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid container item xs={6} sx={{ padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Sales Rep Ranking by Stable Account %
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: "10px 20px 10px 20px",
                border: "1px solid grey",
                backgroundColor: "#FFFFFF",
                gap: 2,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Stable Account Targer &gt; 40%
                </Typography>
              </Grid>
              {rankByStableAccountLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ height: "300px", width: "100%" }}
                >
                  <BarChart
                    series={[
                      {
                        data: salstbChartData,
                        label: "Stable Account %",
                        color: "rgba(49, 144, 242, 1)",
                      },
                    ]}
                    yAxis={[
                      {
                        scaleType: "band",
                        data: salstbChartLabels,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "top", horizontal: "right" },
                        padding: 0,
                        itemMarkWidth: 15,
                        itemMarkHeight: 15,
                      },
                    }}
                    margin={{
                      left: 150,
                      right: 20,
                      top: 40,
                      bottom: 20,
                    }}
                    layout="horizontal"
                    grid={{ vertical: true, horizontal: true }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6} sx={{ padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Sales Rep Ranking by Email %
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: "10px 20px 10px 20px",
                border: "1px solid grey",
                backgroundColor: "#FFFFFF",
                gap: 2,
              }}
            >
              <Grid>
                <Typography sx={{ fontWeight: "bold" }}>
                  Customer Email Percent Target &gt; 95%
                </Typography>{" "}
              </Grid>
              {rankByEmailLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ height: "300px", width: "100%" }}
                >
                  <BarChart
                    series={[
                      {
                        data: salemChartData,
                        label: "Email %",
                        color: "rgba(49, 144, 242, 1)",
                      },
                    ]}
                    yAxis={[
                      {
                        scaleType: "band",
                        data: salemChartLabels,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "top", horizontal: "right" },
                        padding: 0,
                        itemMarkWidth: 15,
                        itemMarkHeight: 15,
                      },
                    }}
                    margin={{
                      left: 150,
                      right: 20,
                      top: 40,
                      bottom: 20,
                    }}
                    layout="horizontal"
                    grid={{ vertical: true, horizontal: true }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6} sx={{ padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Sales Rep Ranking Metrics
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: "10px 20px 10px 20px",
                border: "1px solid grey",
                backgroundColor: "#FFFFFF",
                gap: 2,
              }}
            >
              {clientSalesmenMetricsLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ height: "300px", width: "100%" }}
                >
                  <BarChart
                    xAxis={[
                      {
                        scaleType: "band",
                        data: clientSalesmenChartLabel,
                      },
                    ]}
                    series={[
                      {
                        data: clientSalesmenChartMetricAverage,
                        label: "Average",
                        color: "rgba(90, 238, 158, 1)",
                      },
                      {
                        data: clientSalesmenChartYou,
                        label: "You",
                        color: "rgba(49, 144, 242, 1)",
                      },
                    ]}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6} sx={{ padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Customer Gain & Lost
              </Typography>
            </Grid>
            {rankByWgsDetailsLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "300px",
                  border: "1px solid grey",
                  width: "100%",
                }}
              >
                <MiniSpinner />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "#FFFFFF",
                  gap: 2,
                }}
              >
                <Grid container item xs={12} sx={{}}>
                  <Grid container item xs={3} sx={{ padding: "5px" }}>
                    <Typography>12 Month : </Typography>
                  </Grid>
                  <Grid container item xs={3} sx={{ padding: "5px" }}>
                    Gained : {sgain}
                  </Grid>
                  <Grid container item xs={3} sx={{ padding: "5px" }}>
                    Lost : {slost}
                  </Grid>
                  <Grid container item xs={3} sx={{ padding: "5px" }}>
                    Active Account : {sacc}
                  </Grid>
                </Grid>

                <Grid container item xs={12} sx={{ height: "300px" }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={500}
                      height={400}
                      data={gainLostTotalData}
                      margin={{
                        top: 10,
                        right: 20,
                        bottom: 60,
                        left: 0,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        scale="auto"
                        angle={-80}
                        tickMargin={25}
                        style={{
                          fontSize: "12px",
                        }}
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" />
                      <Bar dataKey="Active" fill="rgba(0, 163, 225, 1)" />
                      <Line dataKey="Lost" stroke="rgba(240,88,34, 1)" />
                      <Line dataKey="Gained" stroke="rgba(0,161,77, 1)" />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid container item xs={12} sx={{ padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Retention Lead
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              padding: "10px 20px 10px 20px",
              border: "1px solid grey",
              backgroundColor: "#FFFFFF",
              gap: 2,
            }}
          >
            <Grid container sx={{}}>
              <Grid container item xs={4} sx={{}}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="Lead"
                    value={selectedSaleRepRetLead}
                    onChange={(_: SyntheticEvent, newValue: any) => {
                      handleRetentionLeadChange(newValue);
                    }}
                    options={retentionLeadOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lead"
                        placeholder="Lead"
                        error={retLeadError}
                        helperText={retLeadErrorMessage}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{}}>
              {selectedSaleRepRetLead && (
                <TableTemplate tableData={userTable} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SaleRepView;
