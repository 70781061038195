import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { updateClientMarketingCost } from "../../../../../store/thunks/systemManagementThunk";
import Spinner from "../../../../../components/reusableComponents/spinner/spinner";
interface UpdateClientMarketingCostProps {
  open: boolean;
  handleUpdateClientMarketingCostClose: (message: string) => void;
  updateClientMarketingCostData: any;
}

const UpdateClientMarketingCostDialog = (
  props: UpdateClientMarketingCostProps
) => {
  const {
    open,
    handleUpdateClientMarketingCostClose,
    updateClientMarketingCostData,
  } = props;

  const dispatch = useAppDispatch();

  const [updateClientMarketingCostError, setUpdateClientMarketingCostError] =
    useState(false);
  const [
    updateClientMarketingCostErrorMessage,
    setUpdateClientMarketingCostErrorMessage,
  ] = useState("");
  const [
    enableSubmitUpdateClientMarketingCost,
    setEnableSubmitUpdateClientMarketingCost,
  ] = useState(true);

  const { updateClientMarketingCostLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [callingCost, setCallingCost] = useState(0);
  const [emailCost, setEmailCost] = useState(0);
  const [surveyCost, setSurveyCost] = useState(0);

  const handleCloseUpdateClientMarketingCost = (
    message: string,
    reason?: any
  ) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setCallingCost(0);
    setEmailCost(0);
    setSurveyCost(0);
    setUpdateClientMarketingCostError(false);
    setUpdateClientMarketingCostErrorMessage("");
    handleUpdateClientMarketingCostClose(message);
  };

  const initialUpdateClientMarketingCost = () => {
    if (updateClientMarketingCostData) {
      setCallingCost(updateClientMarketingCostData?.Calling_Cost);
      setEmailCost(updateClientMarketingCostData?.Email_Cost);
      setSurveyCost(updateClientMarketingCostData?.Survey_Cost);
    }
  };

  useEffect(() => {
    initialUpdateClientMarketingCost();
  }, [updateClientMarketingCostData]);

  const checkEnableSubmitUpdateClientMarketingCost = () => {
    if (
      Number(callingCost) === updateClientMarketingCostData?.Calling_Cost &&
      Number(emailCost) === updateClientMarketingCostData?.Email_Cost &&
      Number(surveyCost) === updateClientMarketingCostData?.Survey_Cost
    ) {
      setEnableSubmitUpdateClientMarketingCost(true);
    } else {
      setEnableSubmitUpdateClientMarketingCost(false);
    }
  };

  useEffect(() => {
    checkEnableSubmitUpdateClientMarketingCost();
  }, [callingCost, emailCost, surveyCost]);

  const handleUpdateClientMarketingCost = () => {
    setUpdateClientMarketingCostError(false);
    setUpdateClientMarketingCostErrorMessage("");
    if (callingCost === 0 || emailCost === 0 || surveyCost === 0) {
      setUpdateClientMarketingCostError(true);
      setUpdateClientMarketingCostErrorMessage("Please enter all the fields");
      return;
    }

    const updateClientMarketingCostPayload: any = {
      client_id: updateClientMarketingCostData?.Client_ID,
      calling_cost: callingCost,
      email_cost: emailCost,
      survey_cost: surveyCost,
    };

    dispatch(
      updateClientMarketingCost({
        updateClientMarketingCostPayload: updateClientMarketingCostPayload,
      })
    )
      .then((updateClientMarketingCostResult: any) => {
        if (updateClientMarketingCostResult.payload.result) {
          handleUpdateClientMarketingCostClose(
            updateClientMarketingCostResult.payload.message
          );
        } else {
          setUpdateClientMarketingCostError(true);
          setUpdateClientMarketingCostErrorMessage(
            updateClientMarketingCostResult.payload.message
          );
        }
      })
      .catch((updateClientMarketingCostError: any) => {
        setUpdateClientMarketingCostError(true);
        setUpdateClientMarketingCostErrorMessage(
          updateClientMarketingCostError.payload.message
        );
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) =>
        handleCloseUpdateClientMarketingCost("", reason)
      }
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Update Client Marketing Cost
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseUpdateClientMarketingCost("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={updateClientMarketingCostLoading} />
      <DialogContent>
        <Grid container sx={{ padding: "20px", gap: 2 }}>
          <Grid item xs={12}>
            <TextField
              label="Client Name"
              value={updateClientMarketingCostData?.client_name}
              disabled={true}
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid container sx={{}}>
            <Grid item xs={4} sx={{ padding: "5px" }}>
              <TextField
                label="Calling Cost"
                value={callingCost}
                inputProps={{ type: "number" }}
                fullWidth
                onChange={(e: any) => {
                  setCallingCost(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ padding: "5px" }}>
              <TextField
                label="Email Cost"
                value={emailCost}
                inputProps={{ type: "number" }}
                fullWidth
                onChange={(e: any) => {
                  setEmailCost(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4} sx={{ padding: "5px" }}>
              <TextField
                label="Survey Cost"
                value={surveyCost}
                inputProps={{ type: "number" }}
                fullWidth
                onChange={(e: any) => {
                  setSurveyCost(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {updateClientMarketingCostError && (
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography sx={{ color: "red" }}>
              {updateClientMarketingCostErrorMessage}
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => handleUpdateClientMarketingCostClose("")}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "red",
            color: "black",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleUpdateClientMarketingCost()}
          disabled={enableSubmitUpdateClientMarketingCost}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "rgba(59, 147, 74, 1)",
            color: "white",
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateClientMarketingCostDialog;
