import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import Spinner from "../../../../../components/reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { disableClient } from "../../../../../store/thunks/systemManagementThunk";

interface DisableClientDialogProps {
  open: any;
  handleDisableClientClose: any;
  disableClientData: any;
}

const DisableClientDialog = (props: DisableClientDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleDisableClientClose, disableClientData } = props;

  const { addClientLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [disableClientError, setDisableClientError] = useState(false);
  const [disableClientErrorMessage, setDisableClientErrorMessage] =
    useState("");

  const handleDisableClient = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    handleDisableClientClose(message);
  };

  const submitDisableClient = () => {
    dispatch(
      disableClient({ client_index: disableClientData?.client_index })
    ).then((result: any) => {
      if (result.payload.result === true) {
        handleDisableClientClose(result.payload.message);
      } else {
        setDisableClientError(true);
        setDisableClientErrorMessage(result.payload.message);
      }
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleDisableClient("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Disable Client
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleDisableClient("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addClientLoading} />
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
          >
            Are you sure that you want to disable Client:{" "}
            <span style={{ fontWeight: "bold" }}>
              {disableClientData?.customer_name}
            </span>
            , Customer ID :{" "}
            <span style={{ fontWeight: "bold" }}>
              {disableClientData?.customer_id}
            </span>
          </Grid>
          {disableClientError && (
            <Grid
              container
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography sx={{ color: "red" }}>
                {disableClientErrorMessage}
              </Typography>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid" }}>
        <Button
          variant="outlined"
          onClick={() => handleDisableClient("")}
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "red",
            color: "black",
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="outlined"
          sx={{
            padding: "5px 10px 5px 10px",
            backgroundColor: "rgba(59, 147, 74, 1)",
            color: "white",
          }}
          onClick={() => submitDisableClient()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableClientDialog;
