import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { getAllClientMarketingCost } from "../../../../store/thunks/systemManagementThunk";
import { Grid, ListItemIcon, Snackbar, Typography } from "@mui/material";
import { keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TableTemplate from "../../../../components/reusableComponents/tableTemplate/tableTemplate";
import UpdateClientMarketingCostDialog from "./updateClientMarketingCost/updateClientMarketingCost";

const ClientMarketingCost = () => {
  const dispatch = useAppDispatch();
  const { getAllClientMarketingCostLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const [allClientMarketingCostList, setAllClientMarketingCostList] =
    useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openUpdateClientMarketingCost, setOpenUpdateClientMarketingCost] =
    useState(false);
  const [updateClientMarketingCostData, setUpdateClientMarketingCostData] =
    useState<any>(null);

  useEffect(() => {
    dispatch(getAllClientMarketingCost({}));
  }, []);

  const {
    data: { data = [] } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getAllClientMarketingCost({}))
        .then((getAllClientMarketingCostResult: any) => {
          if (getAllClientMarketingCostResult.payload) {
            setAllClientMarketingCostList(
              getAllClientMarketingCostResult.payload.marketing_cost
            );
          }
        })
        .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const clientMarketingCostColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "client_name",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Client Name",
          },
          {
            accessorKey: "Client_ID",
            enableClickToCopy: true,
            // filterVariant: "autocomplete",
            header: "Client ID",
          },

          {
            accessorKey: "Calling_Cost",
            enableClickToCopy: true,
            // filterVariant: "autocomplete",
            header: "Calling Cost",
          },
          {
            accessorKey: "Email_Cost",
            enableClickToCopy: true,
            // filterVariant: "autocomplete",
            header: "Email Cost",
          },
          {
            accessorKey: "Survey_Cost",
            enableClickToCopy: true,
            // filterVariant: "autocomplete",
            header: "Survey Cost",
          },
        ],
      },
    ],
    []
  );

  const clientMarketingCostTable = useMaterialReactTable({
    columns: clientMarketingCostColumns,
    data: allClientMarketingCostList ? allClientMarketingCostList : [],
    state: {
      isLoading: getAllClientMarketingCostLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      pagination: { pageIndex: 0, pageSize: 10 },
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        onClick={() => {
          setUpdateClientMarketingCostData(row.original);
          setOpenUpdateClientMarketingCost(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Update Marketing Cost
      </MenuItem>,
    ],
  });

  const handleUpdateClientMarketingCostClose = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setUpdateClientMarketingCostData(null);
    setOpenUpdateClientMarketingCost(false);
  };

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          {"Client Marketing Cost"}
        </Typography>
      </Grid>
      <Grid container item xs={12} sx={{ padding: "20px 0px 20px 0px" }}>
        <TableTemplate tableData={clientMarketingCostTable} />
      </Grid>
      <UpdateClientMarketingCostDialog
        open={openUpdateClientMarketingCost}
        handleUpdateClientMarketingCostClose={(message: string) =>
          handleUpdateClientMarketingCostClose(message)
        }
        updateClientMarketingCostData={updateClientMarketingCostData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Grid>
  );
};

export default ClientMarketingCost;
