import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ClientMarketingCost from "./clientMarketingCost/clientMarketingCost";
import ClientAccount from "./clientAccount/clientAccount";

const clientQueryClient = new QueryClient();
const clientMarketingCostQueryClient = new QueryClient();

const ClientManagement = () => {
  const [
    selectedClientManagementNavigation,
    setSelectedClientManagementNavigation,
  ] = useState("client");

  return (
    <Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          {"Client Management"}
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#4C4C4C",
            borderTop: "5px solid white",
            height: "40px",
            paddingLeft: "10px",
          }}
        >
          <Button
            onClick={() => setSelectedClientManagementNavigation("client")}
            sx={{
              color:
                selectedClientManagementNavigation === "client"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            clients
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() =>
              setSelectedClientManagementNavigation("client_marketing_cost")
            }
            sx={{
              color:
                selectedClientManagementNavigation === "client_marketing_cost"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Client Marketing Cost
          </Button>
        </Grid>
        {selectedClientManagementNavigation === "client" && (
          <QueryClientProvider client={clientQueryClient}>
            <ClientAccount />
          </QueryClientProvider>
        )}
        {selectedClientManagementNavigation === "client_marketing_cost" && (
          <QueryClientProvider client={clientMarketingCostQueryClient}>
            <ClientMarketingCost />
          </QueryClientProvider>
        )}
      </Grid>
    </Grid>
  );
};
export default ClientManagement;
